@use 'mixins' as *;

.hero-block {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--color-gray2);
  color: var(--color-solid-black);
  overflow-y: hidden;

  @include portrait {
    aspect-ratio: var(--hero-portrait-aspect-ratio, 1/1);
  }

  @include landscape {
    aspect-ratio: var(--hero-landscape-aspect-ratio, 16/5);
  }

  &--image {
    color: var(--color-white);
  }

  &--limit-width {
    @include landscape {
      @include content-width();
    }
  }
}

.video-player {
  --media-object-fit: cover;
  --media-object-position: center;
  --controls: none;
  --dialog: none; /* Hide the error dialog */
}

.video-wrapper {
  position: absolute;
  inset: 0;
}

.hero-content {
  inline-size: 100%;
  block-size: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;

  &--overlay {
    background-image: linear-gradient(
      114deg,
      rgb(0 0 0 / 71%) 6%,
      rgb(0 0 0 / 5%) 95%
    );
  }

  &--overlay-thin {
    background-image: linear-gradient(
      114deg,
      rgb(0 0 0 / 30%) 6%,
      rgb(0 0 0 / 5%) 95%
    );
  }
}

.hero-inner {
  inline-size: 100%;
}

div.inner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-regular);

  &--left {
    align-items: flex-start;

    p,
    h1,
    h2 {
      text-align: start;
    }
  }

  &--end {
    justify-content: flex-end;
  }

  p,
  h4,
  h5 {
    text-shadow: 0 0 0.25rem rgb(0 0 0 / 80%);
  }
}

h1.title,
h2.title {
  text-shadow: rgba(0, 0, 0, 0.8) 0px 0px 0.5rem;
  margin-block-end: var(--spacing-regular);
  text-transform: uppercase;
  letter-spacing: 1px;
  max-inline-size: 30ch;

  @include landscape {
    font-size: var(--font-size-header-1);
  }
}

.button-wrapper {
  &--margin {
    margin-block-start: var(--spacing-small);
  }

  &--no-image {
    a {
      border: 1px solid var(--color-black);
      color: var(--color-black);

      @include hover {
        background-color: var(--color-black);
        color: var(--color-white);
      }
    }
  }
}

.description,
.description > p {
  color: var(--description-color, var(--color-black));
  max-inline-size: 60ch;
  font-size: var(--font-size-h5);
}

.inner-button-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 120px;
  max-width: 100%;
}
